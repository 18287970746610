#loading {
  background-color: #fff;
  @include flex-center;
  @include fill-parent;
  position: fixed;
  height:100vh;
  z-index: 9;
}


.spinner {
  width: 40px;
  height: 40px;
  //background-color: #333;
  background-image: url('../images/amd-icon.svg');
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-animation: sk-rotateplane 2s infinite ease-in-out;
  animation: sk-rotateplane 2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% { -webkit-transform: perspective(120px) }
  25% { -webkit-transform: perspective(120px) rotateY(180deg) }
  50% { -webkit-transform: perspective(120px) rotateX(180deg) }
  75% { -webkit-transform: perspective(120px) rotateY(360deg) }
  100% { -webkit-transform: perspective(120px) rotateX(360deg) }
}

@keyframes sk-rotateplane {
  0% { -webkit-transform: perspective(120px) }
  25% { -webkit-transform: perspective(120px) rotateY(180deg) }
  50% { -webkit-transform: perspective(120px) rotateX(180deg) }
  75% { -webkit-transform: perspective(120px) rotateY(360deg) }
  100% { -webkit-transform: perspective(120px) rotateX(360deg) }
}