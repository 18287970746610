.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus, .comment-form input.active.focus[type=submit], .comment-form input.active[type=submit]:focus, .comment-form input.focus[type=submit], .comment-form input[type=submit]:active.focus, .comment-form input[type=submit]:active:focus, .comment-form input[type=submit]:focus {
    outline: none;
}
.btn{
 color: white;
 background: $gray-light;
 border-color: $gray;
}
.btn:active, .btn:focus, .btn:hover{
  color: white;
  background: $gray;
  border-color: $gray-light;
}
.btn-default {
}
.btn-default.active, .btn-default:active, .btn-default:hover, .open>.btn-default.dropdown-toggle {
  background-color: $gray-lighter;
}
.btn-link{
  padding: 0;
  background: none;
  border: none;
  color: $brand-primary;
}
.btn-link:active, .btn-link:focus, .btn-link:hover{
  padding: 0;
  background: none;
  border: none;
  color: darken($brand-primary, 10%);
}
.btn-teal, .btn-primary{
  background-color: $brand-primary;
  border-color: darken($brand-primary, 10%);
}
.btn-teal:active, .btn-teal:focus, .btn-primary:hover, .btn-primary:active, .btn-primary:focus, .btn-primary:hover{
  background-color: darken($brand-primary, 10%);
  border-color: $brand-primary;
}
.btn-green, .btn-success{
  background-color: $brand-success;
  border-color: darken($brand-success, 10%);
}
.btn-green:active, .btn-green:focus, .btn-green:hover, .btn-success:active, .btn-success:focus, .btn-success:hover{
  background-color: darken($brand-success, 10%);
  border-color: $brand-success;
}
.btn-purple, .btn-info{
  background-color: $brand-info;
  border-color: darken($brand-info, 10%);
}
.btn-purple:active, .btn-purple:focus, .btn-purple:hover, .btn-info:active, .btn-info:focus, .btn-info:hover{
  background-color: darken($brand-info, 10%);
  border-color: $brand-info;
}
.btn-orange, .btn-warning{
  background-color: $brand-warning;
  border-color: darken($brand-warning, 10%);
}
.btn-orange:active, .btn-orange:focus, .btn-orange:hover, .btn-warning:active, .btn-warning:focus, .btn-warning:hover{
  background-color: darken($brand-warning, 10%);
  border-color: $brand-warning;
}
.btn-red, .btn-danger{
  background-color: $brand-danger;
  border-color: darken($brand-danger, 10%);
}
.btn-red:active, .btn-red:focus, .btn-red:hover, .btn-danger:active, .btn-danger:focus, .btn-danger:hover{
  background-color: darken($brand-danger, 10%);
  border-color: $brand-danger;
}
//ACF Button Styles
.acf-button{
  @extend .btn;
}
.button-primary{
  @extend .btn-primary;
}
.page.user-upload .media-frame-toolbar .media-toolbar {
  top: initial;
  bottom: initial;
}