/* Small Devices, 767px and below */

@media only screen and (max-width : $screen-xs-max) {
  //Mobile Nav and Header
  #navToggle{
    cursor: pointer;

    i.fa-bars{
      color: white;
      font-size: 2em;
      padding: 3px 6px;
    }
  }
  .logo-mobile{

    a img{
     height: 25px;
     width: auto;
    }
  }
  .breadcrumbs{
    display: none;
  }
	#mainNavHolder {
	    width: 80%;
	    z-index: 2;
	    box-shadow: 0px 0px 3px 1px rgba(0,0,0,.4);
	    left: -81%;
	}
	//Main
	.wrap {
		margin-left: 0;
	}
	//Category Listings
  body.category .media-left{
   display: none;
  }
  //Single Post
  #productBrief{

    .media-body {
      display: table-row;
    }
    .media-right {
      display: inline-block;
    }
  }
}