//Search Results Page Styles
.search-post-list{

  article{

    &.media{
      margin-top: 25px;
    }
    &.media:first-child {
      margin-top: 0;
    }
    h3.entry-title{

      .btn{
        font-size: 24px;
        padding: 0;
      }
    }
    .download-info {
      padding-left: 0;
    }
    .badge{
      display: inline-block;
      min-width: 10px;
      padding: 3px 7px;
      font-size: 18px;
      font-weight: 300;
      color: #fff;
      line-height: 1;
      vertical-align: middle;
      white-space: nowrap;
      text-align: center;
      background-color: #b3b3b3;
      border-radius: 5px;

      &.badge-primary{
        background-color: $brand-primary;
      }
      &.badge-danger{
        background-color: $brand-danger;
      }
    }
  }
}
ul.search-associated-products{
  display: inline;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline;
  }
  li:after {
    content: ", ";
  }
  li:last-child:after {
      content: "";
  }
}