// Search form
.btn:focus {
  outline: none;
}
@mixin placeHolderStyle {
  font-family: $headings-font-family;
  text-transform: uppercase;
}
::-webkit-input-placeholder {
  @include placeHolderStyle;
}

::-webkit-input-placeholder {
   @include placeHolderStyle;
}

:-moz-placeholder { /* Firefox 18- */
   @include placeHolderStyle;
}

::-moz-placeholder {  /* Firefox 19+ */
   @include placeHolderStyle;
}

:-ms-input-placeholder {
   @include placeHolderStyle;
}
.search-form {
  position: relative;
  margin-top: 15px;
  input {
    padding-right: 35px;
    font-weight: 700;
  }
  button {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background: none;
    color: $gray-light;
    &:hover {
      background: $brand-primary;
      border: 1px solid $brand-primary;
      color: #fff;
    }
  }
}
.search-form label {
  font-weight: normal;
}
.search-form .search-field {

}
.search-form .search-submit {

}

.comment-form input[type=email]:focus, .comment-form input[type=text]:focus, .comment-form input[type=url]:focus, .comment-form textarea:focus, .form-control:focus, .search-form .search-field:focus {
    border-color: $brand-primary;
    outline: 0;
    box-shadow: 0 0 1px 1px transparentize($brand-primary, .5);
}

//SLA Form
.form-body {
	display: none;
}

#slaModal .form-body {
	display: block;
}

.confirmation-link {
  display: none;
  text-align: center;
  font-size: 2.5rem;
  margin: 20px auto 0;
  padding: 0 80px 80px;
}


// Gravity Forms Overrides

#slaModal {
  .gform_wrapper form li.agreement-body {
    max-height: 300px;
    overflow-y: scroll;
    border-bottom: 1px solid $gray-lighter;
    margin-bottom: 30px;
    padding: 15px;
    font-size: 1.2rem;
    box-shadow: inset 0px 1px 1px 1px rgba(0,0,0,.3);
  }

  .gform_wrapper li.gfield_html {
    max-width: 100%;
  }

  .gform_wrapper {
  	margin: 0 auto;
  	max-width: 100%;
  }

  .gform_wrapper .gform_body {
     max-width: 100%;
  }

  .gform_heading {
  	text-align: center;
  	h3 {
  		margin-bottom: 10px;
  	}
  }

  .validation_error {
  	background: lighten($brand-warning, 35%);
  	border: 1px solid lighten($brand-warning, 20%);
  	color: $brand-warning;
  	padding: 5px;
  	text-align: center;
  	margin: 5px 0;
  }

  .gform_body {
  	ul {
  		list-style: none;
  		padding: 0;
  	}
  }

  .gfield_required {
      color: $brand-warning;
      font-size: 1.3rem;
      padding: 0 0 0 3px;
      position: absolute;
  }

  .gfield_label {
      font-size: 1.5rem;
      text-transform: uppercase;
      margin-top: 10px;
  }

  .ginput_container {
  	input, textarea {
  		@extend .form-control;
  		border-radius: 2px;
  	}
  	textarea {
  		height: 100px;
  	}
  }

  .gform_validation_container {
  	display: none;
  }

  .gform_footer {
  	input[type=submit] {
  		@extend .btn;
  		@extend .btn-primary;
  		@extend .btn-lg;
  	}
  }

  .validation_message {
  	color: $brand-warning;
  	font-size: 1.4rem;
  }

  .gform_footer {
  	text-align: center;
  	position: relative;
  }

  .gform_ajax_spinner {
  	position: absolute;
  	height: 32px;
  	width: 32px;
  	top: calc(50% - 16px);
  	left: calc(50% - 16px);
  }

  .gform_confirmation_wrapper {
  	text-align: center;
  	font-size: 2.5rem;
  	padding: 80px 80px 0;
  	//@include flex-center;
  }
}
// Gravity Forms Overrides for Feedback Form
#gform_wrapper_5 {

  .gform_body {
    @extend .row;

    .top_label .gfield_label {
      font-size: 1.5rem;
    }
    .gfield_description {
      font-size: 1.3rem;
    }
  }
  .gform_footer {
    @extend .row;

  	input[type=submit] {
  		@extend .btn;
  		@extend .btn-primary;
  		@extend .btn-lg;
  		margin: 0 15px;
  	}
  }
}