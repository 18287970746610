// Common components
a:focus {
  outline: none;
}

// Lists
@mixin angle-list {
  padding-left: 25px;
  li {
    list-style-type: none;
    text-indent: -12px;
    margin-bottom: 4px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &:before {
        font-family: FontAwesome;
        content: "\f105";
        color: $gray-light;
        padding-right: 0.5em;
    }
  }
}

// layered full height & width absolute positioned element
@mixin fill-parent {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

// Flexbox centering
@mixin flex-center {
	@include flexbox;
	@include justify-content(center);
	@include align-items(center);
}

// Flexbox that centers content vertically
@mixin flex-v-center {
  @include flexbox;
  @include justify-content(space-between);
  @include align-items(center);
}

// Non-flexbox vertical centering
@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
}