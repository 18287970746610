html, body, #outerWrapper, .wrap {
	height: 100%;
}

body {
  overflow-x: hidden;
}

.wrap {
	margin-left: 300px;
}

#wrapOverlay {
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,.5);
  position: absolute;
  z-index: 1;
  display: none;
}

#outerWrapper.nav-open {
  #mainNavHolder {
    left: 0;
  }
  #wrapOverlay {
    display: block;
  }
}

main {
  height: calc(100% - 50px);
}

/* Box sizing */
#productPanels {
  height: calc(100% - 15px);
}

#primaryPanels {
  height: calc(50% - 15px);
  margin-bottom: 15px;
}

#downloadPanels {
  height: 50%;
}

#primaryPanels, #downloadPanels {
  > div, .panel {
    height: 100%;
  }
  .panel-body {
    overflow-y: scroll;
    height: calc(100% - 45px);
  }
}

#catSelect{
  float: none;
  position: absolute;
  right: 35px;
}

#downloadPanel {
  #downloadTabs {
    height: 45px;
  }
  .panel {
    height: calc(100% - 45px);
  }
  .panel-body {
    height: 100%;
  }
}


#overviewPanel .panel-body {
  //overflow-y: hidden;
  position: relative;
}

#showMore {
  display: none;
  position: absolute;
  bottom: 0;
  @include gradient-vertical(transparent, #fff, 0%, 100%);
  width: 100%;
  padding: 40px 15px 15px;
  left: 0;
  text-align: center;
  button {
    font-size: 1.5rem;
    text-transform: uppercase;
  }
}

/*
#downloadPanels {
    height: calc(60% - 60px);
}
*/

#downloadPanels {
  @include flexbox;
  > div {
    @include flex-grow(1);
  }
}

#tablePanel {
  .panel-body {
    overflow-x: scroll;
  }
}
