.page-header.jumbotron {
    margin-top: 0;
    margin-bottom: 15px;
    color: #fff;
    background-size: cover;
    background-position: right;
    text-shadow: 1px 1px 2px $gray-base;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    h1 {
        font-size: 4rem;
        font-weight: 500;
        margin: 0;
    }
    p {
        font-style: italic;
        margin: 0;
        font-size: 18px;
        text-align: left;
    }
}

.page-header .row {
  @include flex-v-center;
}

.home .panel-body {
    h2 {
        margin-top: 0;
        font-size: 2.5rem;
        margin-bottom: 5px;
    }
    p {
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.home #downloadPanel .panel-body {
    padding: 15px;
    .download-info {
        padding-left: 0;
        padding-right: 0;
    }
    .download:last-of-type {
        border-bottom: none;
        td {
            padding-bottom:0;
        }
    }
    .download:first-of-type {
        td {
            padding-top:0;
        }
    }
}

body.login {
    .wrap {
        margin: 0 auto;
        max-width: 768px;
    }
    .panel h2, .panel p {
        text-align: center;
    }
    .panel.page-header p {
        text-align: left;
    }
    .login-buttons {
        max-width: 500px;
        margin: 30px auto;
        text-transform: uppercase;
    }
    .create-account, .forgot-password {
        color: lighten($gray, 10%);
        p {
            text-align: left; 
            font-size: 1.2rem;
            line-height: 1.2;
        }
    }
    .footer-notes {
        color: lighten($gray, 20%);
        font-style: italic;
        font-size: 1.1rem;
        margin-bottom: 30px;
        padding-left: 15px;
        padding-right: 15px;
        line-height: 1.3;
    }
}

#dashboardBottom {
    .panel-default > .panel-heading {
        background: $brand-primary;
        text-transform: uppercase;
        font-weight: 500;
        a {
            color: #fff;
            &:hover {
                color: lighten($brand-primary, 50%);
            }
        }
    }
    .panel-body {
        height: 300px;
        overflow-y: scroll;
    }
}

#pressReleases {
    li {
        line-height: 1.3;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $gray-lighter;
        &:last-of-type {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }
    }
}