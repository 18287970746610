/* Large Devices, 1200px and below */
@media only screen and (max-width : $screen-lg) {
	//Main nav & wrapper
	#mainNavHolder {
	    width: 230px;
	}
	.wrap {
	    margin-left: 230px;
	}
	header.banner {
	    padding: 10px 0;
	    .btn {
	    	margin-top: 0;
	    }
	}

	//Page
	.page-header.jumbotron h1 {
	    h1 {
	    	font-size: 3.5rem;
	    }
	    p {
	    	line-height: 1.2;
	    }
	}

	//Dashboard bottom
	#dashboardBottom .panel-body {
	    height: 150px;
	    overflow-y: scroll;
	}

	//Single Post
	#dashboardBottom{

    	.panel-default{

  	  		.panel-heading{

		        a{
		          font-size: 1.3em;
		        }
      		}
  		}
  	}
  #downloadPanels{
    display: block;

    .download-panel{
      margin-bottom: 15px;
    }
  }
}