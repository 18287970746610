.modal-open .modal {
	overflow-x: hidden;
	overflow-y: auto;
	position: fixed;
	height: 100%;
	width: 100%;
	padding: 0!important;
}

.modal-lg {
	max-width: 1200px;
	width: auto;
}

.close {
	float: right;
}

.modal-header h4 {
	float: left;
}

#product-image-modal {
	padding: 0!important;
	.modal-dialog {
	    width: 100%;
	    height: 100%;
	    margin: 0;
	}
	.modal-content {
	    box-shadow: none;
	    border: none;
	    border-radius: 0;
	    height: 100%;
	    background: none;
	}
	.modal-header {
	    border: none;
	    position: absolute;
	    top: 0;
	    right: 0;
	    z-index: 2;
	}
	.close {
		color: transparentize(#fff, .5);
		margin: 0;
		padding: 4px 10px;
		font-size: 2rem;
		opacity: 1;
		&:hover {
			color: #fff;
			opacity: 1;
		}
	}
	
	.modal-body {
		height: 100%;
		z-index: 1;
		padding: 20px;
		.carousel-inner, .item {
			height: 100%;
		}
		.item {
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		}
	}	
}

.modal-title {
	float: left;
}