// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary: #00aab5; //teal;
$brand-success: #a6ce39; //green
$brand-info: #812990; //purple
$brand-warning: #f26522; //orange
$brand-danger: #ed1c24; //red

$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 70%); 
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee

$font-family-sans-serif: 'Roboto', Calibri, Arial, sans-serif;
$headings-font-family:  'KlavikaCondensed', Calibri, Arial, sans-serif;
$headings-font-weight:    400;

$body-bg:              #f6f6f6;
$text-color:           $gray-base;

$border-radius-base:        0;
$border-radius-large:       0;
$border-radius-small:       0;

$btn-default-color:              $gray;
$btn-default-border:             $gray;