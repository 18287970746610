/* Medium Devices, 991px and below */
@media only screen and (max-width : $screen-sm-max) {

  //Single Post
	#dashboardBottom{

    .panel-default{

  	  .panel-heading{

      }
      .panel-body {
        height: auto;
      }
  	}
  }
}