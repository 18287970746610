/* ==========================================================================
   Video/Photo Gallery Page
   ========================================================================== */
.video-container {

  .video-js {
    width: 100%;
    height: auto;
   /* padding-top: 55.25%;*/
  }
}
.photo-container, .video-modal-button {
  display: inline-block !important;
  position: relative !important;
  overflow: hidden;
  margin-top: 20px;
}

.overlay {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.5s, opacity 0.5s linear;
}

.photo-container a, .video-modal-button .video-thumb {
  color: transparent;

  img.thumbnail{
    margin-bottom: 0;
  }
}

.photo-container a:hover .overlay, .video-modal-button .video-thumb:hover .overlay {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
  text-align: center;
  position: absolute;
  background-color: rgba($brand-primary, 0.7);
  color: #fff;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.overlay div {
  margin: auto;
  position: absolute;
  top: 35%;
  left: 0;
  bottom: 0;
  right: 0
}
.vid-modal{

  .modal-footer{
    text-align: left;
  }
}