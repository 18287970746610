/* Klavika Fonts */

// Regular Condensed
@font-face {
   font-family: 'KlavikaCondensed';
      src: url('../fonts/KlavikaWebRegularCond.eot');
      src: url('../fonts/KlavikaWebRegularCondensed.woff') format('woff');
   font-weight: 400;
   font-style: normal;
}

// Bold Condensed
@font-face {
   font-family: 'KlavikaCondensed';
      src: url('../fonts/KlavikaWebBoldCond.eot');
      src: url('../fonts/KlavikaWebBoldCondensed.woff') format('woff');
   font-weight: 700;
   font-style: normal;
}

// Light Condensed
@font-face {
   font-family: 'KlavikaCondensed';
      src: url('../fonts/KlavikaWebLightCond.eot');
      src: url('../fonts/KlavikaWebLightCondensed.woff') format('woff');
   font-weight: 300;
   font-style: normal;
}

// Medium Condensed
@font-face {
   font-family: 'KlavikaCondensed';
      src: url('../fonts/KlavikaWebMediumCond.eot');
      src: url('../fonts/KlavikaWebMediumCondensed.woff') format('woff');
   font-weight: 500;
   font-style: normal;
}

// Medium Condensed Italic
@font-face {
   font-family: 'KlavikaCondensed';
      src: url('../fonts/KlavikaWebMediumCondItalic.eot');
      src: url('../fonts/KlavikaWebMediumCondensedItalic.woff') format('woff');
   font-weight: 500;
   font-style: italic;
}