.panel-default {
    border: none;
    box-shadow: 0 1px 1px rgba(0,0,0,.05), inset 0 0 0 1px #ddd;
    >.panel-heading {
        background: $gray-base;
        color: #fff;
        height: 45px;
    }
    h1 {
        font-size: 2rem;
    }
}

#softwarePanel{

  #yadcf-filter-wrapper--external_filter_container, #yadcf-filter-wrapper--external_filter_container_2{
   display: inline;
   color: #333;
   margin-right: 15px;

   button#yadcf-filter--SoftwareDownloadsTable-1-reset, button#yadcf-filter--SoftwareDownloadsTable-2-reset{
     @extend .btn-primary;
   }
  }
  .yadcf-filter.inuse{
    background: $brand-primary;
    color: white;
  }

  #SoftwareDownloadsTable_filter{
    display: none;
  }

  .panel-heading {
    background: $brand-danger;
    h2 {
      margin: 0;
      font-size: 16px;
      padding-top: 3px;
    }
    .navbar {
      margin-bottom: 0;
      min-height: 0;
    }
    .nav>li, .nav>li>a {
        position: relative;
        display: inline-block;
    }
    .nav>li {
      padding: 0 15px;
    }
    .nav>li>a {
        padding: 0;
    }
    .dropdown > a {
      color: #fff;
    }
    .dropdown > a{
      color: #fff;
      &:hover, &:focus, &:active {
        color: #000;
        background: $brand-danger;
        text-decoration: none;
      }
    }
    .nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
      background: none;
      color: #000;
      border-bottom: 1px dotted #000;
    }
    .dropdown-menu {
      margin-top: 11px;

      li{

        a.data-filter-select{
          display: block;
          padding: 3px 20px;
          clear: both;
          font-weight: 400;
          line-height: 1.428571429;
          color: #333;
          white-space: nowrap;

          &.active{
            background: $brand-primary;
            color: white;
          }
        }
        a.clear{
          background: darken($brand-success, 10%);

          &:hover{
           background: lighten($brand-success, 5%);
          }
          &.active{
          background: darken($brand-success, 10%);
          }
        }
      }
    }
  }
}

.panel-body {
    font-weight: 400;
    line-height: 1.5;
    font-size: 1.3rem;
}
/* PRODUCTS IMAGE/THUMBNAIL - CAROUSEL */
.product-image-container{
  display: block;
  background: white;
  border: 2px solid $gray-lighter;
  @include flexbox;
  @include align-items(center);
  position: relative;
  height: 100px;
  width: 100px;
}
a.carousel-modal-link{
  color: $gray;
  position: relative;
  padding: 5px;
  text-align: center;
  width: 100%;
  &:hover, &:focus {
    text-decoration: none;
  }
  span{
    position: absolute;
    right: 0;
    top: 25px;
    font-size: 2rem;
    width: 100%;
    text-align: center;
    color: #fff;
    text-shadow: 0 0 2px #000000;
    padding: 5px;
  }
  span.indicators {
    font-size: .8rem;
    top: 60px;
    i {
        margin-right: 2px;
        &:last-of-type {
            margin: 0;
        }
    }
  }
  .fa-table {
    font-size: 50pt;
    margin: 0 auto;
    display: block;
    color: $brand-primary;
  }
}
.product-thumb-caption {
    text-align: center;
    bottom: 0;
    line-height: 1;
    font-size: 1rem;
    padding: 4px 0;
    color: $gray-light;
    text-transform: uppercase;
}
.carousel-image-modal{
  margin-top: 15%;

  .modal-content{
    height: 550px;

    .modal-header{
      position: relative;
      border: none;
      width: 80%;
      margin: 0 auto;

      button{

        .fa-close{
          font-size: 3rem;
          color: $brand-warning;
          text-shadow: none;
        }
      }
    }
    .item{
      height: auto;
      width: 80%;
      max-height: 450px;
      margin: 0 auto;
    }
  }
  .carousel-content {
    background: #1c1839;
    position: absolute;
    bottom: 0;
    left: 80px;
    max-width: 400px;
    text-align: left;
    color: #fff;
    padding: 30px;
    line-height: 1.2;
  }
}
.carousel-control i {
	font-size: 4rem;
	color: #fff;
	opacity: 1;
	@include vertical-align(absolute);
}
.carousel-control {
	text-align: left;
	padding: 40px;
	&.right {
		text-align: right;
	}
}

/* MAIN CONTENT SECTION */
#productBrief {
  ul {
      @include angle-list;
      columns: 3;
      column-gap: 30px;
      ul {
        columns: 1;
      }
  }
}

/* KEY FEATURES SECTION */
#keyFeatures {
  line-height: 1.3;
  h2 {
      font-size: 2.2rem;
      text-transform: uppercase;
      border-bottom: 3px solid $gray-lighter;
      font-weight: 400;
  }
  ul {
      font-weight: 400;
      @include angle-list;
  }
  li {
      margin: 3px 0 5px 0;
  }
  p {
      font-weight: 500;
      margin-bottom: 0;
  }
  ol {
      font-size: 1rem;
      font-weight: 400;
      font-style: italic;
      margin-top: 30px;
      color: $gray-light;
      padding-left: 15px;
  }
}

.features-text {
    columns: 3;
    column-gap: 30px;
}

/* DOWNLOADS TABS */
.nav-tabs {
    border-bottom: 3px solid $brand-danger;
    height: 100%;
    @include flexbox;
    >li {
        float: none;
        margin-bottom: 0px;
    }
    >li>a {
        background: #fff;
        color: $gray-base;
        height: 100%;
        border: none;
        //border-right: 1px solid $gray-lighter;
        font-family: $headings-font-family;
        font-size: 16px;
        text-align: center;
        line-height: 1.1;
        @include flexbox;
        @include align-items(center);
        padding: 7px 8px;
        margin-left: -1;
        margin-right: 0;
        border: 1px solid $gray-lighter;
    }
    >li:first-of-type>a {
      margin-left: 0;
    }
    >li:last-of-type>a {
        //border-right: none;
    }
    >li>a:hover, >li>a:focus {
        background: darken($brand-danger, 5%);
        color: #fff;
    }
    >li.active>a, >li.active>a:focus, >li.active>a:hover  {
        background: $brand-danger;
        color: #fff;
        border: none;
        border-right: 1px solid $gray-lighter;
        &:last-of-type {
            border-right: none;
        }
    }
}

#devBoardLink {
    padding: 5px 10px;
    background: $gray;
    color: #fff;
    text-align: center;
    span {
      margin-right:10px;
      font-size: 1rem;
      text-transform:uppercase;
    }
}

// Downloads
.download-panel {
    .panel-body {
        padding: 0;

        .af-success{
          padding: 15px;
        }
    }
    table {
        width: 100%;
    }
    thead {
        background: none;
        border-bottom: 1px solid darken($gray-lighter, 10%);
        font-weight: 700;
        td {
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }
}

.download {
    color: $gray-light;
    font-size: 1.15rem;
    border-bottom: 1px solid $gray-lighter;
    a, .btn-link {
        font-size: 1.3rem;
        line-height: 1.1;
        margin-top: 3px;
        display: block;
        text-align: left;
        white-space: normal;
    }
    .btn-link {
        padding: 0;
    }
    >td {
        padding-top: 5px;
        padding-bottom: 5px;
    }
}
.download-info {
    //@include flexbox;
}
.download-icon {
    margin-right: 5px;
}
.download-file {

}
.download-info {
    padding-left: 15px;
}
.download-info, .download-version {
    padding-right: 10px;
}
.download-date {
    padding-right: 15px;
}
.download-category {
    padding-right: 15px;
}
.tag {
  margin: 0 3px;
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
}

table[data-sortable] th {
  position: relative;
}

table[data-sortable] th:after {
    border: 4px solid transparent;
    margin-left: 3px;
    position: absolute;
}

table[data-sortable] th[data-sorted-direction=descending]:after {
    border-top-color: $gray-light;
}

table[data-sortable] th[data-sorted-direction=ascending]:after {
   border-bottom-color: $gray-light;
}

/* PRODUCT TABLES*/
.product-tables{
  .tablepress{
    thead th {
      background-color: transparent;
      font-family: $headings-font-family;
      font-weight: 400;
      text-align: center;
      vertical-align: middle;
      font-size: 1.5rem;
      line-height: 1.1;
    }
    tbody{
        td {
            border: 1px solid $gray-lighter;
            text-align: center;
            vertical-align: middle;
            white-space: nowrap;
        }
        td[colspan]{
            background: $gray-lighter;
            font-weight: 700;
            text-transform: uppercase;
        }
    }
  }
  .tablepress.table-header-rotated {
    th {
      height: 160px;
      white-space: nowrap;
      border: 1px solid $gray-lighter;
      div {
        -ms-transform: translate(0,58px) rotate(-90deg);
        transform: translate(0,58px) rotate(-90deg);
        width: 20px;
        margin: 0 auto;
      }
    }
  }
}

/* Category page */

body.category {
  .panel-body {
    padding: 30px;
  }
  .media {
    margin: 15px 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .media-left {
    padding-right: 15px;
    img {
      height: 100px;
      width: 100px;
      border: 1px solid $gray-lighter;
    }
  }
}

