header.banner {
    padding: 10px 0;
    height: 50px;
    .brand {
        color: $gray-base;
        font-weight: 700;
        font-family: $headings-font-family;
        font-size: 1.8rem;
        margin-right: 7px;
        &:hover {
            color: $brand-primary;
        }
    }
    .btn {
        margin-top: -5px;
    }
    .breadcrumbs {
        font-size: 1.2rem;
        i {
            margin: 0 4px;
            color: $gray-light;
        }
    }
}