// datatables layout
.dt-buttons.btn-group{
  @extend .pull-right;
}
.dataTables_filter{
  @extend .pull-left;
}
.buttons-copy{
  @extend .btn-purple;
}
.buttons-excel{
  @extend .btn-green;
}
.buttons-pdf{
  @extend .btn-orange;
}
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    color: #fff!important;
}

//recent updates datatable
#FilterTableSeach{
  @extend .form-group;

  .dataTables_filter{
    float: none !important;
    display: block;
    clear: both;

    label{
      display: block;
    }
  }
}

#ResourceTable_wrapper{

  .top{
    padding: 8px;
  }
  .bottom{
    padding: 8px;
  }
}
