#mainNavHolder {
	width: 300px;
	background-color: #fff;
	height:100%;
	position: fixed;
	overflow-x: hidden;
	box-shadow: 0 1px 1px 0px rgba(0,0,0,.5);
	@include flexbox;
	@include flex-direction(column);
	@include justify-content(space-between);
}

#navTop {
	@include flex-grow(1);
	position: relative;
}

#navHeader {
	padding: 15px;
	img {
		height: 25px;
		width: auto;
	}
}

#mainNav {
	//position: relative;
	//height: calc(100% - 119px);
	background-color: #fff;
	font-family: $headings-font-family;
	border-left: none;
	border-right: none;
	top: 139px;
}


.nav>li>a:focus, .nav>li>a:hover {
	background-color: transparent;
}
/*
.nav>li{
  a.mm-fullsubopen:hover {
	  background-color: transparent;
  }
  span.parent-menu-title{
    background-color: white;
    &:hover{
      background-color: $gray-lighter;
    }
  }
}
*/
.mm-menu.mm-hoverselected .mm-listview>li>a.mm-fullsubopen+a,.mm-menu.mm-hoverselected .mm-listview>li>a.mm-fullsubopen+span,.mm-menu.mm-hoverselected .mm-listview>li>a:not(.mm-fullsubopen),.mm-menu.mm-parentselected .mm-listview>li>a.mm-fullsubopen+a,.mm-menu.mm-parentselected .mm-listview>li>a.mm-fullsubopen+span,.mm-menu.mm-parentselected .mm-listview>li>a:not(.mm-fullsubopen){-webkit-transition:background .4s ease;transition:background .4s ease}.mm-menu.mm-hoverselected .mm-listview>li>a.mm-fullsubopen:hover+span,.mm-menu.mm-hoverselected .mm-listview>li>a:not(.mm-fullsubopen):hover,.mm-menu.mm-parentselected .mm-listview>li>a.mm-selected.mm-fullsubopen+a,.mm-menu.mm-parentselected .mm-listview>li>a.mm-selected.mm-fullsubopen+span,.mm-menu.mm-parentselected .mm-listview>li>a.mm-selected:not(.mm-fullsubopen){background-color:$gray-lighter;}

.mm-panel.mm-hasnavbar .mm-navbar {
  display: block;
  border: none;
}

.menu-item {
	font-weight: 500;
	font-style: italic;
	//text-transform: uppercase;
	font-size: 1.8rem;
    &.current-post-ancestor a {
    	color: $brand-primary;
    }
}

.mm-menu .mm-listview>li.mm-selected>a:not(.mm-next), .mm-menu .mm-listview>li.mm-selected>span, .menu-item.active a {
	background: $brand-primary;
	color: #fff;
	&:hover {
		background: darken($brand-primary, .1);
	}
}

.mm-menu .mm-navbar>*, .mm-menu .mm-navbar a, .menu-title {
	color: $gray;
	font-size: 1.8rem;
	text-align: center;
	//margin-top: 30px;
	&:first-of-type {
		//margin-top: 0;
	}
}

.mm-listview>li:not(.mm-divider):after {
    left: 0;
}

.mm-iconpanel .mm-panel.mm-opened {
  border: none;
}

#navFooter {
  background: $gray-lighter;
  //bottom: 30px;
  display: block;
  //position: absolute;
  width: 100%;
  text-transform: uppercase;
  .nav>li>a{
    color: $gray-dark;
    font-size: 1.4rem;
    &:hover {
    	background: $gray-dark;
    	color: #fff;
    }
  }
}
#copyright {
	background: $gray-base;
	color: $gray-light;
	font-size: 1.1rem;
    line-height: 1.1;
    padding: 10px;
    text-align: center;
    a {
    	margin: 0 3px;
    	white-space: nowrap;
    	&:hover {
    		color: lighten($brand-primary, 10%);
    	}
    }
}
/* ==========================================================================
   Pagination
   ========================================================================== */
.nav-links {
  text-align: center;
    width: 100%;
    margin-top: 3rem;
    .page-numbers {
        border: 1px solid $brand-primary;
        color: $gray-dark;
        padding: 0 .5rem;
        margin: 0 .2rem;
        font-weight: 600;
        &.current, &:hover {
          background: $brand-primary;
          color: #fff;
        }
        &.next:after, &.prev:before {
          font-family: FontAwesome;
        }
        &.next:after {
            content: "\f101";
            margin-left: .5rem;
        }
        &.prev:before {
            content: "\f100";
            margin-right: .5rem;
        }
    }
}